import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";


//imported
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";


const Layout = (props) => {
  const override = css`
    display: block;
    margin: 0 auto;
  `;
  let [color, setColor] = useState("#860000");

  //sidebar
  const [isOpen, setIsOpen] = useState(false);
  const sidebarHideShow = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    // sidebarHideShow()
  }, [isOpen]);

  return (
    <div className={`container-scroller ${isOpen ? "sidebar-icon-only" : ""}`}>
      <Helmet titleTemplate={`%s | ${process.env.REACT_APP_APPNAME}`} />
      <div className="container-fluid page-body-wrapper">
      {props.loader == true && (
        <div className="overlay_screen">
              <div className="main-loader">
                <ClipLoader
                  color={color}
                  loading={props.loader}
                  css={override}
                  size={80}
                  speedMultiplier={1}
                />
              </div>
              </div>
            )}
        <Sidebar isOpen={isOpen} />
        <div className="main-panel ms-auto position-relative">
         <Header sidebarHideShow={sidebarHideShow} />
          <div className="content-wrapper py-3 px-5">
            {React.cloneElement(props.children)}
           
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};
const mapStateToPros = (state) => {
  return {
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth,
    loader: state.persistStore.loader,
  };
};

export default connect(mapStateToPros)(Layout);
