import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import moment from "moment";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
//imported
import * as Path from "routes/paths";
import { CSVLink } from "react-csv";
import ReactPagination from "components/shared/ReactPagination";
import {
  FORMAT_TEXT,
  DATE_FORMAT_TIME_,
  FORMAT_TEXT_,
  DATE_FORMAT_TIME,
  DATE_FORMAT_FOR_DB,
  tradesDatacsv,
  usersDatacsv,
  paymentDatacsv,
  taskDatacsv,
  lgogsDatacsv,
  affiliatecsvData,
} from "helpers/common";
import { REPORT, PAGE_LIMIT } from "helpers/viewContent";
import GraphReport from "./GraphReport";
//services
import { getList_ } from "store/services/commonService";

const Listing = ({ dispatch, itemsCountPerPage, totalItemsCount }) => {
  //history
  const history = useHistory();
  //pagination
  const [isLoading, setIsLoading] = useState(false);

  const [filter, setFilter] = useState({
    page: 1,
    limit: PAGE_LIMIT,
    orderBy: "createdAt",
    order: 1,
    type: "trade", // logs, trade, users, payments, tasks, affilates, affilatesTransaction
  });
  const [userData, setUserData] = useState([]);

  const [csvDataUser, setCsvDataUser] = useState([]);
  const [tradeData, settradeData] = useState([]);
  const [csvDataT, setCsvDataT] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [csvDataP, setCsvDataP] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [csvDataTask, setCsvDataTask] = useState([]);
  const [logData, setLogData] = useState([]);
  const [csvDataLogs, setCsvDataLogs] = useState([]);
  const [affilatesData, setAffilatesData] = useState([]);
  const [csvDataAffilates, setCsvDataAffilates] = useState([]);

  const [affilatesTransaction, setAffilatesTransaction] = useState([]);
  const [csvDataAffilatesTransaction, setCsvDataAffilatesTransaction] =
    useState([]);
  const [key, setKey] = useState(0);

  //pagination
  const handlePageChange = (page) => {
    setFilter({
      ...filter,
      page: page,
    });
    window.scrollTo(0, 0);
  };
  const [field, setfields] = useState({
    startDate: "",
    endDate: "",
  });
  // let searchFilterstartDate = DATE_FORMAT_FOR_DB(field.startDate);
  let searchFilterstartDate = field.startDate;
  // let searchFilterendDate = DATE_FORMAT_FOR_DB(field.endDate);
  let searchFilterendDate = field.endDate;

  const handleChangePicker = async (selectedDate, name) => {
    // Perform actions based on the selected date and field name
    let dateFormat = selectedDate ? selectedDate.format("DD/MM/YYYY") : "";
    setfields((prev) => ({
      ...prev,
      [name]: dateFormat,
    }));
    // Rest of your code...
  };

  const fetchData = async () => {
    try {
      let query = {
        ...filter,
        startDate: field?.startDate ? searchFilterstartDate : "",
        endDate: field?.endDate ? searchFilterendDate : "",
        url: "report/listWithFilter",
      };
      setIsLoading(true);
      let res = await dispatch(getList_(query));
      if (filter?.type === "trade") {
        settradeData(res?.data?.data);
        let arr = await tradesDatacsv(res?.data?.data);
        setCsvDataT(arr);
      } else if (filter?.type === "users") {
        setUserData(res?.data?.data);
        let arrP = await usersDatacsv(res?.data?.data);
        setCsvDataUser(arrP);
      } else if (filter?.type === "payments") {
        setPaymentData(res?.data?.data);
        let arrP = await paymentDatacsv(res?.data?.data);
        setCsvDataP(arrP);
      } else if (filter?.type === "tasks") {
        setTaskData(res?.data?.data);
        let arrP = await taskDatacsv(res?.data?.data);
        setCsvDataTask(arrP);
      } else if (filter?.type === "logs") {
        setLogData(res?.data?.data);
        let arrP = await lgogsDatacsv(res?.data?.data);
        setCsvDataLogs(arrP);
      } else if (filter?.type === "affilates") {
        setAffilatesData(res?.data?.data);
        let arrP = await affiliatecsvData(res?.data?.data);
        setCsvDataAffilates(arrP);
      } else if (filter?.type === "affilatesTransaction") {
        setAffilatesTransaction(res?.data?.data);
        let arrP = await affiliatecsvData(res?.data?.data);
        setCsvDataAffilatesTransaction(arrP);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  useEffect(() => {
    fetchData();
  }, [filter, field]);

  const clearSerchFilter = () => {
    setfields({
      startDate: "",
      endDate: "",
    });
    setKey((prevKey) => prevKey + 1);
  };

  const handleSearchChange = (event) => {
    const inputValue = event.target.value;
    setFilter({
      ...filter,
      type: inputValue,
    });
  };
  const handleSortingFunc = (value) => {
    // Check if the clicked column is the same as the current orderBy column
    if (filter.orderBy === value) {
      // If yes, toggle the order (ascending to descending or vice versa)
      setFilter({
        ...filter,
        order: filter.order === -1 ? 1 : -1,
      });
    } else {
      // If no, set the orderBy to the clicked column and order to ascending
      setFilter({
        ...filter,
        order: -1,
        orderBy: value,
      });
    }
  };
  const renderTradesGraph = (symbol) => {
    const url = `https://www.tradingview.com/symbols/${symbol}/`;
    window.open(url, "_blank");
  };

  //render html
  const renderListTrades_ = () => {
    return (
      tradeData?.length > 0 &&
      tradeData.map((list, i) => (
        <tr key={i} style={{ cursor: "pointer" }}>
          <td>
            {list?.userDetails?.first_name
              ? list?.userDetails?.first_name
              : "N/A"}
          </td>
          <td>
            {list?.userDetails?.accountId
              ? list?.userDetails?.accountId
              : "N/A"}
          </td>
          <td className="green_status">
            $
            {list?.userDetails?.walletCredit
              ? (list.userDetails?.walletCredit).toFixed(2)
              : "0"}
          </td>
          <td>
            $
            {list?.userDetails?.equity
              ? (list.userDetails?.equity).toFixed(2)
              : "0"}
          </td>
          <td>{list.shortName ? list?.shortName : list.name}</td>
          <td>${list?.price ? (list?.price).toFixed(2) : "0"}</td>
          <td
            onClick={() => renderTradesGraph(list?.symbol)}
            className="cursor-pointer"
            title="View"
          >
            <b>{list?.symbol}</b>
          </td>
          <td>${list?.boughtPrice ? (list?.boughtPrice).toFixed(2) : "0"}</td>
          <td>
            ${list?.current_price ? (list?.current_price).toFixed(2) : "0"}
          </td>
          <td>
            <label
              className={
                list?.unrealized_plpc && list.unrealized_plpc < 0
                  ? "badge badge-danger"
                  : "badge badge-success"
              }
            >
              {list?.unrealized_plpc ? (list?.unrealized_plpc).toFixed(2) : "0"}
            </label>
          </td>
          <td>{list.qty}</td>
        </tr>
      ))
    );
  };

  const renderListPayment_ = () => {
    return (
      paymentData?.length > 0 &&
      paymentData.map((list, i) => (
        <tr key={i} style={{ cursor: "pointer" }}>
          <td
            onClick={() =>
              list?.userDetails?.name
                ? history.push(`${Path.userview}/${list?.userDetails?._id}`)
                : console.log("no value")
            }
            title="User View"
          >
            <b>{list?.userDetails?.name ? list?.userDetails?.name : "N/A"}</b>
          </td>
          <td>
            <b>{list.transactionId ? list.transactionId : "N/A"}</b>
          </td>
          <td>{DATE_FORMAT_TIME_(list.createdAt)}</td>
          <td>
            $
            {list.amount != null && !isNaN(list.amount)
              ? parseInt(list.amount).toFixed(2)
              : "0"}
          </td>
          <td>{list.transactionType}</td>
          <td>
            <label className="badge badge-success payment_status">
              {list.status}
            </label>
          </td>
        </tr>
      ))
    );
  };

  const renderListUser_ = () => {
    return (
      userData?.length > 0 &&
      userData?.map((list, i) => (
        <tr key={i} style={{ cursor: "pointer" }}>
          <td>{list?.first_name ? FORMAT_TEXT(list?.first_name) : "N/A"}</td>
          <td>{list?.last_name ? FORMAT_TEXT(list?.last_name) : "N/A"}</td>
          <td>{list?.email ? FORMAT_TEXT(list?.email) : "N/A"}</td>
          <td>{list?.city ? FORMAT_TEXT(list?.city) : "N/A"}</td>
          <td>{list?.state ? list?.state : "N/A"}</td>
          <td>$ {list?.sellStock ? `${list?.sellStock}` : "0"}</td>
          <td>$ {list?.buyStock ? `${list?.buyStock}` : "0"}</td>
          <td>
            $
            {list.walletCredit != null && !isNaN(list.walletCredit)
              ? list.walletCredit.toFixed(2)
              : "0"}
          </td>
          <td>
            {list?.accountData?.account_number
              ? list?.accountData?.account_number
              : "N/A"}
          </td>
          <td>
            $
            {list.equity != null && !isNaN(list.equity)
              ? list.equity.toFixed(2)
              : "0"}
          </td>
          <td>{DATE_FORMAT_TIME_(list.createdAt)}</td>
        </tr>
      ))
    );
  };

  const renderListTask_ = () => {
    return (
      taskData?.length > 0 &&
      taskData.map((list, i) => (
        <tr key={i} style={{ cursor: "pointer" }}>
          <td>
            {list?.subjectDetails?.name
              ? FORMAT_TEXT(list?.subjectDetails?.name)
              : FORMAT_TEXT(list?.subjectDetails?.email)}
          </td>

          <td>
            {list?.assignDetails?.name
              ? FORMAT_TEXT(list?.assignDetails?.name)
              : FORMAT_TEXT(list?.assignDetails?.email)}
          </td>
          <td>{list.title ? list.title : "N/A"}</td>
          <td>{list.comment ? list.comment : "N/A"}</td>
          <td>{list.endDate ? DATE_FORMAT_TIME_(list?.endDate) : "N/A"}</td>
          <td>
            <i
              class={`mdi mdi-checkbox-blank-circle ${
                list.status === "upcoming"
                  ? "info"
                  : list.status === "inactive"
                  ? "primary"
                  : "success"
              }`}
            ></i>{" "}
            {list.status === "upcoming"
              ? "Tomorrow"
              : list.status === "inactive"
              ? "Overdue"
              : "Today"}
          </td>
        </tr>
      ))
    );
  };
  const renderListLogs_ = () => {
    return (
      logData?.length > 0 &&
      logData.map((list, i) => {
        return (
          <tr key={i} style={{ cursor: "pointer" }}>
            <td>
              {list?.updateData?.name
                ? FORMAT_TEXT_(list?.updateData?.name)
                : "N/A"}
            </td>
            <td>{FORMAT_TEXT_(list.text)}</td>
            <td>{list.type}</td>
            <td>{DATE_FORMAT_TIME(list.createdAt)}</td>
          </tr>
        );
      })
    );
  };

  const renderListAffilate_ = () => {
    return (
      affilatesData?.length > 0 &&
      affilatesData.map((list, i) => {
        return (
          <tr key={i} style={{ cursor: "pointer" }}>
            <td>
              {list?.affiliatedUser?.affiliatedCode
                ? list?.affiliatedUser?.affiliatedCode
                : "N/A"}
            </td>
            <td>
              {list.affiliatedUser?.accountData?.account_number
                ? list.affiliatedUser?.accountData?.account_number
                : "N/A"}
            </td>
            <td>
              {list?.createdAt ? DATE_FORMAT_TIME(list?.createdAt) : "N/A"}
            </td>
            <td> {list?.symbol ? list?.symbol : "N/A"}</td>
            <td>
              ${" "}
              {list?.treadAmount && list?.treadAmount > 0
                ? `${parseFloat(list?.treadAmount)}`
                : "0"}
            </td>
            <td>
              {" "}
              ${" "}
              {list?.amount && list?.amount > 0
                ? `${parseFloat(list?.amount).toFixed(2)}`
                : "0"}
            </td>
          </tr>
        );
      })
    );
  };
  const renderListAffilateTranscation_ = () => {
    return (
      affilatesTransaction?.length > 0 &&
      affilatesTransaction.map((list, i) => {
        return (
          <tr key={i} style={{ cursor: "pointer" }}>
            <td>
              {list?.affiliatedUser?.affiliatedCode
                ? list?.affiliatedUser?.affiliatedCode
                : "N/A"}
            </td>
            <td>
              {list.affiliatedUser?.accountData?.account_number
                ? list.affiliatedUser?.accountData?.account_number
                : "N/A"}
            </td>
            <td>
              {list?.createdAt ? DATE_FORMAT_TIME(list?.createdAt) : "N/A"}
            </td>
            <td> {list?.symbol ? list?.symbol : "N/A"}</td>
            <td>
              ${" "}
              {list?.treadAmount && list?.treadAmount > 0
                ? `${parseFloat(list?.treadAmount)}`
                : "0"}
            </td>
            <td>
              {" "}
              ${" "}
              {list?.amount && list?.amount > 0
                ? `${parseFloat(list?.amount).toFixed(2)}`
                : "0"}
            </td>
          </tr>
        );
      })
    );
  };

  return (
    <>
      <Helmet title={REPORT.CURRENT_MODULE} />
      <div className="page-header">
        <h3 className="page-title">{REPORT.CURRENT_MODULE}</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={Path.report}>{REPORT.CURRENT_MODULE}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Listing
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card graph_card">
            <div className="card-body">
              <h4 class="card-title">
                <div class="fillte_main flex-wrap d-flex justify-content-between align-items-center">
                  <div class="center_fillter d-flex flex-wrap gap-3 align-items-center">
                    <div class="selector_fillter d-flex gap-3 align-items-center">
                      <div class="filter_drop_filter">
                        <h6>Title</h6>
                        <select
                          class="form-select filler_search"
                          onChange={(e) => handleSearchChange(e)}
                        >
                          <option value="" disabled={true}>
                            Select{" "}
                          </option>
                          <option
                            value="trade"
                            selected={
                              filter?.type === "trade" ? "selected" : ""
                            }
                          >
                            Trade{" "}
                          </option>
                          <option
                            value="payments"
                            selected={
                              filter?.type === "payments" ? "selected" : ""
                            }
                          >
                            Payment{" "}
                          </option>
                          <option
                            value="users"
                            selected={
                              filter?.type === "users" ? "selected" : ""
                            }
                          >
                            User{" "}
                          </option>
                          <option
                            value="tasks"
                            selected={
                              filter?.type === "tasks" ? "selected" : ""
                            }
                          >
                            Task{" "}
                          </option>
                          <option
                            value="logs"
                            selected={filter?.type === "logs" ? "selected" : ""}
                          >
                            Logs{" "}
                          </option>
                          <option
                            value="affilates"
                            selected={
                              filter?.type === "affilates" ? "selected" : ""
                            }
                          >
                            Affilate{" "}
                          </option>
                          <option
                            value="affilatesTransaction"
                            selected={
                              filter?.type === "affilatesTransaction"
                                ? "selected"
                                : ""
                            }
                          >
                            Affilate Transaction{" "}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="button_cta d-flex align-items-end">
                    <CSVLink
                      data={
                        filter?.type === "payments"
                          ? csvDataP
                          : filter?.type === "users"
                          ? csvDataUser
                          : filter?.type === "tasks"
                          ? csvDataTask
                          : filter?.type === "logs"
                          ? csvDataLogs
                          : filter?.type === "affilates"
                          ? csvDataAffilates
                          : filter?.type === "affilatesTransaction"
                          ? csvDataAffilatesTransaction
                          : csvDataT
                      }
                      className="btn btn-gradient-primary btn-lg"
                    >
                      Export
                    </CSVLink>
                  </div>
                </div>
              </h4>
              <div class="fillte_main flex-wrap d-flex gap-3 align-items-end">
                <div class="filter_drop_filter">
                  <h6>From Date</h6>
                  <Datetime
                    key={key}
                    value={field.startDate || ""}
                    inputProps={{
                      placeholder: "DD/MM/YYYY",
                      readOnly: true,
                      className: "form-control class-time-date-time",
                    }}
                    name="startDate"
                    dateFormat="DD/MM/YYYY"
                    timeFormat={false}
                    isValidDate={(current) => {
                      return current.isSameOrBefore(moment(), "day");
                    }}
                    onChange={(selectedDate) =>
                      handleChangePicker(selectedDate, "startDate")
                    }
                    closeOnSelect={true}
                  />
                </div>
                <div class="filter_drop_filter">
                  <h6>To Date</h6>
                  <Datetime
                    key={key}
                    value={field.endDate || ""}
                    inputProps={{
                      placeholder: "DD/MM/YYYY",
                      readOnly: true,
                      className: "form-control class-time-date-time",
                    }}
                    name="endDate"
                    dateFormat="DD/MM/YYYY"
                    timeFormat={false}
                    isValidDate={(current) => {
                      // Parse the current date string in DD/MM/YYYY format to a moment object
                      const currentDate = moment(current, "DD/MM/YYYY", true);
                      // Parse the start date string in DD/MM/YYYY format to a moment object
                      const startDate = moment(
                        field?.startDate,
                        "DD/MM/YYYY",
                        true
                      );
                      // Check if the current date is the same as or after the start date
                      return (
                        currentDate.isValid() &&
                        startDate.isValid() &&
                        currentDate.isSameOrAfter(startDate, "day")
                      );
                    }}
                    onChange={(selectedDate) =>
                      handleChangePicker(selectedDate, "endDate")
                    }
                    closeOnSelect={true}
                  />
                </div>
                <div class="filter_drop_filter">
                  <button
                    type="button"
                    class="btn btn-gradient-primary btn-lg px-3"
                    onClick={clearSerchFilter}
                  >
                    Clear Filter
                  </button>
                </div>
              </div>
              <h4 className="card-title"></h4>
              {/* trades view*/}
              {filter?.type === "trade" && (
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th onClick={() => handleSortingFunc("user_name")}>
                          {" "}
                          Client's Name{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy === "user_name"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy === "user_name"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th onClick={() => handleSortingFunc("accountId")}>
                          {" "}
                          Account Id{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy === "accountId"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy === "accountId"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th onClick={() => handleSortingFunc("walletCredit")}>
                          {" "}
                          Balance{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy === "walletCredit"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy === "walletCredit"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th onClick={() => handleSortingFunc("equity")}>
                          {" "}
                          Equity{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy === "equity"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy === "equity"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th onClick={() => handleSortingFunc("name")}>
                          {" "}
                          Stock Name{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 && filter?.orderBy === "name"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 && filter?.orderBy === "name"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th onClick={() => handleSortingFunc("price")}>
                          {" "}
                          Price
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy === "price"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy === "price"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th> Symbol</th>
                        <th onClick={() => handleSortingFunc("boughtPrice")}>
                          {" "}
                          Bought Price{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy === "boughtPrice"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy === "boughtPrice"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th onClick={() => handleSortingFunc("current_price")}>
                          {" "}
                          Current Price{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy === "current_price"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy === "current_price"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th> PLPC</th>
                        <th> Qty</th>
                      </tr>
                    </thead>
                    <tbody>{renderListTrades_()}</tbody>
                  </table>
                </div>
              )}
              {filter?.type === "trade" && (
                <>
                  {tradeData?.length > 0 && (
                    <div className="pagination-box-review">
                      <ReactPagination
                        activePage={filter?.page}
                        itemsCountPerPage={PAGE_LIMIT}
                        totalItemsCount={totalItemsCount}
                        handlePageChange={handlePageChange}
                      />
                    </div>
                  )}
                  {tradeData?.length == 0 && (
                    <div className="row">
                      <div className="col-md-6 pt-5">
                        <span>No data found...</span>
                      </div>
                    </div>
                  )}
                </>
              )}
              {/* payment view*/}
              {filter?.type === "payments" && (
                <div class="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th onClick={() => handleSortingFunc("name")}>
                          {" "}
                          Customer{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 && filter?.orderBy === "name"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 && filter?.orderBy === "name"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th onClick={() => handleSortingFunc("transactionId")}>
                          {" "}
                          Transaction Id{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy === "transactionId"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy === "transactionId"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th onClick={() => handleSortingFunc("createdAt")}>
                          {" "}
                          Date & Time{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy === "createdAt"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy === "createdAt"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th onClick={() => handleSortingFunc("amount")}>
                          {" "}
                          Transaction Amount{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy === "amount"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy === "amount"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th> Type </th>
                        <th> Status </th>
                      </tr>
                    </thead>
                    <tbody>{renderListPayment_()}</tbody>
                  </table>
                </div>
              )}
              {filter?.type === "payments" && (
                <>
                  {paymentData?.length > 0 && (
                    <div className="pagination-box-review">
                      <ReactPagination
                        activePage={filter?.page}
                        itemsCountPerPage={PAGE_LIMIT}
                        totalItemsCount={totalItemsCount}
                        handlePageChange={handlePageChange}
                      />
                    </div>
                  )}
                  {paymentData?.length == 0 && (
                    <div className="row">
                      <div className="col-md-6 pt-5">
                        <span>No data found...</span>
                      </div>
                    </div>
                  )}
                </>
              )}
              {/* user view*/}
              {filter?.type === "users" && (
                <div class="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th onClick={() => handleSortingFunc("first_name")}>
                          {" "}
                          First Name{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy === "first_name"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy === "first_name"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th onClick={() => handleSortingFunc("last_name")}>
                          {" "}
                          Last Name{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy === "last_name"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy === "last_name"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th onClick={() => handleSortingFunc("email")}>
                          {" "}
                          Email Id{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy === "email"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy === "email"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>

                        <th onClick={() => handleSortingFunc("city")}>
                          {" "}
                          City{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 && filter?.orderBy === "city"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 && filter?.orderBy === "city"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>

                        <th onClick={() => handleSortingFunc("state")}>
                          {" "}
                          State{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy === "state"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy === "state"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>

                        <th onClick={() => handleSortingFunc("sellStock")}>
                          {" "}
                          Buy Stocks{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy === "sellStock"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy === "sellStock"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th onClick={() => handleSortingFunc("buyStock")}>
                          {" "}
                          Sold Stocks
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy === "buyStock"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy === "buyStock"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th onClick={() => handleSortingFunc("walletCredit")}>
                          {" "}
                          Wallet Balance{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy === "walletCredit"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy === "walletCredit"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th onClick={() => handleSortingFunc("accountId")}>
                          {" "}
                          Account Number{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy === "accountId"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy === "accountId"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th onClick={() => handleSortingFunc("equity")}>
                          {" "}
                          Wallet Equity{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy === "equity"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy === "equity"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th onClick={() => handleSortingFunc("createdAt")}>
                          {" "}
                          Registration Date{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy === "createdAt"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy === "createdAt"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody>{renderListUser_()}</tbody>
                  </table>
                </div>
              )}
              {filter?.type === "users" && (
                <>
                  {userData?.length > 0 && (
                    <div className="pagination-box-review">
                      <ReactPagination
                        activePage={filter?.page}
                        itemsCountPerPage={PAGE_LIMIT}
                        totalItemsCount={totalItemsCount}
                        handlePageChange={handlePageChange}
                      />
                    </div>
                  )}
                  {userData?.length == 0 && (
                    <div className="row">
                      <div className="col-md-6 pt-5">
                        <span>No data found...</span>
                      </div>
                    </div>
                  )}
                </>
              )}
              {/* task view*/}
              {filter?.type === "tasks" && (
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th onClick={() => handleSortingFunc("subject")}>
                          {" "}
                          Subject{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy === "subject"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy === "subject"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th onClick={() => handleSortingFunc("assigned")}>
                          {" "}
                          Assgined{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy === "assigned"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy === "assigned"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th onClick={() => handleSortingFunc("title")}>
                          {" "}
                          Title{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy === "title"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy === "title"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th> Comment </th>
                        <th onClick={() => handleSortingFunc("endDate")}>
                          {" "}
                          Expire{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy === "endDate"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy === "endDate"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th> Status </th>
                      </tr>
                    </thead>
                    <tbody>{renderListTask_()}</tbody>
                  </table>
                </div>
              )}
              {filter?.type === "tasks" && (
                <>
                  {taskData?.length > 0 && (
                    <div className="pagination-box-review">
                      <ReactPagination
                        activePage={filter?.page}
                        itemsCountPerPage={PAGE_LIMIT}
                        totalItemsCount={totalItemsCount}
                        handlePageChange={handlePageChange}
                      />
                    </div>
                  )}
                  {taskData?.length == 0 && (
                    <div className="row">
                      <div className="col-md-6 pt-5">
                        <span>No data found...</span>
                      </div>
                    </div>
                  )}
                </>
              )}
              {/* logs view*/}
              {filter?.type === "logs" && (
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th onClick={() => handleSortingFunc("name")}>
                          {" "}
                          User{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 && filter?.orderBy === "name"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 && filter?.orderBy === "name"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th> Logs </th>
                        <th> Type </th>
                        <th onClick={() => handleSortingFunc("createdAt")}>
                          {" "}
                          Created At{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy === "createdAt"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy === "createdAt"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody>{renderListLogs_()}</tbody>
                  </table>
                </div>
              )}
              {filter?.type === "logs" && (
                <>
                  {logData?.length > 0 && (
                    <div className="pagination-box-review">
                      <ReactPagination
                        activePage={filter?.page}
                        itemsCountPerPage={PAGE_LIMIT}
                        totalItemsCount={totalItemsCount}
                        handlePageChange={handlePageChange}
                      />
                    </div>
                  )}
                  {logData?.length == 0 && (
                    <div className="row">
                      <div className="col-md-6 pt-5">
                        <span>No data found...</span>
                      </div>
                    </div>
                  )}
                </>
              )}
              {/* affiliate view*/}
              {filter?.type === "affilates" && (
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th
                          onClick={() =>
                            handleSortingFunc(
                              "list.affiliatedUser.affiliatedByCode"
                            )
                          }
                        >
                          {" "}
                          Affiliate Code{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy ===
                                "list.affiliatedUser.affiliatedByCode"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy ===
                                "list.affiliatedUser.affiliatedByCode"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th
                          onClick={() =>
                            handleSortingFunc(
                              "list.affiliatedUser.accountData.account_number"
                            )
                          }
                        >
                          {" "}
                          Account id of client{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy ===
                                "list.affiliatedUser.accountData.account_number"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy ===
                                "list.affiliatedUser.accountData.account_number"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th onClick={() => handleSortingFunc("list.createdAt")}>
                          {" "}
                          Trade Date{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy === "list.createdAt"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy === "list.createdAt"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th
                          onClick={() =>
                            handleSortingFunc("list.orderData.symbol")
                          }
                        >
                          {" "}
                          Symbol{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy === "list.orderData.symbol"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy === "list.orderData.symbol"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th onClick={() => handleSortingFunc("list.amount")}>
                          {" "}
                          Amount{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy === "list.amount"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy === "list.amount"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th
                          onClick={() =>
                            handleSortingFunc("list.orderData.commission")
                          }
                        >
                          {" "}
                          Commission Amount{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy === "list.orderData.commission"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy === "list.orderData.commission"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody>{renderListAffilate_()}</tbody>
                  </table>
                </div>
              )}
              {filter?.type === "affilates" && (
                <>
                  {affilatesData?.length > 0 && (
                    <div className="pagination-box-review">
                      <ReactPagination
                        activePage={filter?.page}
                        itemsCountPerPage={PAGE_LIMIT}
                        totalItemsCount={totalItemsCount}
                        handlePageChange={handlePageChange}
                      />
                    </div>
                  )}
                  {affilatesData?.length == 0 && (
                    <div className="row">
                      <div className="col-md-6 pt-5">
                        <span>No data found...</span>
                      </div>
                    </div>
                  )}
                </>
              )}
              {/* affilatesTransaction view*/}
              {filter?.type === "affilatesTransaction" && (
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th
                          onClick={() =>
                            handleSortingFunc(
                              "list.affiliatedUser.affiliatedByCode"
                            )
                          }
                        >
                          {" "}
                          Affiliate Code{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy ===
                                "list.affiliatedUser.affiliatedByCode"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy ===
                                "list.affiliatedUser.affiliatedByCode"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th
                          onClick={() =>
                            handleSortingFunc(
                              "list.affiliatedUser.accountData.account_number"
                            )
                          }
                        >
                          {" "}
                          Account id of client{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy ===
                                "list.affiliatedUser.accountData.account_number"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy ===
                                "list.affiliatedUser.accountData.account_number"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th onClick={() => handleSortingFunc("list.createdAt")}>
                          {" "}
                          Trade Date{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy === "list.createdAt"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy === "list.createdAt"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th
                          onClick={() =>
                            handleSortingFunc("list.orderData.symbol")
                          }
                        >
                          {" "}
                          Symbol{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy === "list.orderData.symbol"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy === "list.orderData.symbol"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th onClick={() => handleSortingFunc("list.amount")}>
                          {" "}
                          Amount{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy === "list.amount"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy === "list.amount"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                        <th
                          onClick={() =>
                            handleSortingFunc("list.orderData.commission")
                          }
                        >
                          {" "}
                          Commission Amount{" "}
                          <i
                            className={`cursor-pointer mdi mdi-arrow-${
                              filter?.order === -1 &&
                              filter?.orderBy === "list.orderData.commission"
                                ? "up"
                                : "down"
                            }`}
                            title={
                              filter?.order === -1 &&
                              filter?.orderBy === "list.orderData.commission"
                                ? "Desc"
                                : "Asc"
                            }
                          ></i>{" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody>{renderListAffilateTranscation_()}</tbody>
                  </table>
                </div>
              )}
              {filter?.type === "affilatesTransaction" && (
                <>
                  {affilatesTransaction?.length > 0 && (
                    <div className="pagination-box-review">
                      <ReactPagination
                        activePage={filter?.page}
                        itemsCountPerPage={PAGE_LIMIT}
                        totalItemsCount={totalItemsCount}
                        handlePageChange={handlePageChange}
                      />
                    </div>
                  )}
                  {affilatesTransaction?.length == 0 && (
                    <div className="row">
                      <div className="col-md-6 pt-5">
                        <span>No data found...</span>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    itemsCountPerPage: state.Common.itemsCountPerPage,
    totalItemsCount: state.Common.totalItemsCount,
    loader: state.Common.loader,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Listing);
