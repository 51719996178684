import {AUTH_LOGIN, AUTH_CHECK,AUTH_LOGOUT, PROFILE, UPDATE_PASSWORD, UPDATE_PROFILE, FORGOT_PASSWORD, RESET_PASSWORD_FORM, RESET_PASSWORD } from 'store/actions/auth';
import Http from 'helpers/Http';

const initialState = {
    isAuthenticated: false,
    user: null,
    loader:false,
};

//login 
const authLogin = (state, payload) => {
  const { data } = payload;
  localStorage.setItem('token', data?.data?.token);
  localStorage.setItem('user', JSON.stringify(data?.data));
  localStorage.setItem('adminid', JSON.stringify(data?.data?._id));
  Http.defaults.headers.common.token = `${data?.data?.token}`;
  Http.defaults.headers.common.adminid = `${data?.data?._id}`;
  const stateObj = {
    ...state,
      isAuthenticated: true,
      user:data?.data
    };
  return stateObj;
};

//check auth
const checkAuth = (state) => {
  const stateObj = {
    ...state,
    isAuthenticated: !!localStorage.getItem('token'),
    user: JSON.parse(localStorage.getItem('user')),
    adminid: JSON.parse(localStorage.getItem('adminid')),
  };
  if (state.isAuthenticated) {
    Http.defaults.headers.common.token = `${localStorage.getItem(
      'token',
    )}`;
    Http.defaults.headers.common.adminid = `${localStorage.getItem(
      'adminid',
    )}`;
  }
  return stateObj;
};

//logout
const authLogout = (state) => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('adminid');
  const stateObj = {
    ...state,
    isAuthenticated: false,
    user: null,
  };
  return stateObj;
};

//get profile
const getProfile = (state, payload) => {
  const { data } = payload;
  Http.defaults.headers.common.token = `${data?.token}`;
  Http.defaults.headers.common.adminid = `${data?._id}`;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    user:data,
  };
  return stateObj;
};

//update profile
const updateProfile = (state, payload) => {
  const { body } = payload;
  const stateObj = {
    ...state,
    isAuthenticated: true,
  };
  return stateObj;
};

//update password
const updatePassword = (state, payload) => {
  const { data } = payload;
  const stateObj = {
    ...state,
    isAuthenticated: true,
  };
  return stateObj;
};

//forgotPassword
const forgotPassword = (state) => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('adminid');
  const stateObj = {
    ...state,
    isAuthenticated: false,
    user: null,
  };
  return stateObj;
};

//resetPasswordForm
const resetPasswordForm = (state) => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('adminid');
  const stateObj = {
    ...state,
    isAuthenticated: false,
    user: null,
  };
  return stateObj;
};

//resetPassword
const resetPassword = (state) => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('adminid');
  const stateObj = {
    ...state,
    isAuthenticated: false,
    user: null,
  };
  return stateObj;
};

const Auth = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case AUTH_LOGIN:
          return authLogin(state, payload);
        case AUTH_CHECK:
          return checkAuth(state, payload);
        case AUTH_LOGOUT:
          return authLogout(state);
        case PROFILE:
          return getProfile(state, payload);
        case UPDATE_PROFILE:
          return updateProfile(state, payload);
        case UPDATE_PASSWORD:
          return updatePassword(state, payload);
        case FORGOT_PASSWORD:
          return forgotPassword(state);
        case RESET_PASSWORD_FORM:
          return resetPasswordForm(state);
        case RESET_PASSWORD:
          return resetPassword(state);
        default:
        return state;
    };
}
export default Auth;