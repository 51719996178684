import React, { useState, useCallback, useEffect, useMemo } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { PAGE_LIMIT } from "helpers/viewContent";
import ReactPagination from "components/shared/ReactPagination";
import SearchFilter from "components/shared/SearchFilter";
import { debounce } from "lodash";
import * as Path from "routes/paths";

import { tradesSchduleDatacsv } from "helpers/common";
import { TRADES, PAGE_LIMIT_TRADE } from "helpers/viewContent";

//services
import { addData_, getList_ } from "store/services/commonService";

const ScheduleOrder = ({ dispatch, tabIndex, handleShow, type }) => {
  const [activePage, setActivePage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [key, setKey] = useState(0);
  const [csvDataP, setCsvDataP] = useState([]);
  const [itemsCountS, setItemsCountS] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [filter, setFilter] = useState({
    page: activePage,
    limit: PAGE_LIMIT,
    order: 1,
    search: "",
    orderBy: "createdAt",
  });
  const [listing, setListing] = useState([]);

  //filter
  let listToDisplay = listing;
  if (filter?.search !== "") {
    const searchTerm = filter?.search.toLowerCase();
    listToDisplay = listing.filter((list) => {
      const itemName = list?.name?.toLowerCase();
      const itemClientName = list?.userDetails?.first_name.toLowerCase();
      const itemaccountId = list?.userDetails?.accountId.toLowerCase();
      return (
        itemName?.includes(searchTerm) ||
        itemClientName?.includes(searchTerm) ||
        itemaccountId?.includes(searchTerm)
      );
    });
  }
  const [fields, setfields] = useState({
    search_date: "",
  });
  let searchFilterDate = fields.search_date;

  const fetchData = async () => {
    try {
      let query = {
        ...filter,
        url: "getScheduledOrders",
      };
      setIsLoading(true);
      let res = await dispatch(getList_(query));
      setListing(res?.data?.data);
      setTotalItemsCount(res?.data?.totalcount);
      setItemsCountS(res?.data?.totalcount);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const csvDataDownload_ = async () => {
    try {
      // Initialize query object
      let query = {};

      // Build query based on tabIndex
      if (tabIndex === 3) {
        query = {
          ...filter,
          url: "getScheduledOrders",
        };
      }
      // Set loading state
      setIsLoading(true);
      console.log("Fetching data with query:", query);

      // Dispatch the query
      const res = await dispatch(getList_(query));
      console.log("Data fetched:", res);

      // Process the response based on tabIndex
      if (res) {
        let arrP;
        if (tabIndex === 3) {
          arrP = await tradesSchduleDatacsv(res?.data?.data);
          setCsvDataP(arrP);
        }
      }
      // Reset loading state
      setIsLoading(false);
      console.log("Loading state set to false");
    } catch (err) {
      // Reset loading state and log error
      setIsLoading(false);
      console.log(err);
    }
  };

  const handleSortingFunc = (value) => {
    // Check if the clicked column is the same as the current orderBy column
    if (filter.orderBy === value) {
      // If yes, toggle the order (ascending to descending or vice versa)
      setFilter({
        ...filter,
        order: filter.order === -1 ? 1 : -1,
      });
    } else {
      // If no, set the orderBy to the clicked column and order to ascending
      setFilter({
        ...filter,
        order: -1,
        orderBy: value,
      });
    }
  };
  const renderTradesGraph = (symbol) => {
    const url = `https://www.tradingview.com/symbols/${symbol}/`;
    window.open(url, "_blank");
  };
  useEffect(() => {
    fetchData();
    csvDataDownload_();
  }, [filter, tabIndex]);

  //pagination
  const handlePageChange = (page) => {
    setFilter({
      ...filter,
      page: page,
    });
    window.scrollTo(0, 0);
  };

  const handleSearchChange = (event) => {
    const inputValue = event.target.value;
    setFilter({
      ...filter,
      search: inputValue,
    });
  };

  const debouncedFetchData = useMemo(() => {
    return debounce(handleSearchChange, 300);
  }, []);

  const clearSerchFilter = () => {
    setFilter({
      ...filter,
      search: "",
      status: "",
    });
    setfields({
      search_date: "",
    });
    setKey((prevKey) => prevKey + 1);
  };
  const selectSearchOption = [];
  const getStatus = (status, list) => {
    if (status === "accepted") {
      return <label className={"badge badge-success"}>{status}</label>;
    } else if (status === "pending") {
      return <label className={"badge badge-pending"}>{status}</label>;
    } else if (status === "closed") {
      return <label className={"badge badge-close"}>{status}</label>;
    } else if (status === "accepted" && list?.type === "sell") {
      return <label className={"badge badge-close"}>closed</label>;
    } else {
      return <label className={"badge badge-danger"}>{status}</label>;
    }
  };

  const renderListS_ = () => {
    return (
      listToDisplay?.length > 0 &&
      listToDisplay.map((list, i) => (
        <tr key={i} style={{ cursor: "pointer" }} title="View">
          <td>
            {list?.userDetails?.first_name
              ? list?.userDetails?.first_name
              : "N/A"}
          </td>
          <td
            onClick={() =>
              list?.userDetails?.accountId
                ? handleShow(list, type)
                : console.log("not exists")
            }
            title="view"
            className="cursor-pointer"
          >
            <b>
              {list?.userDetails?.accountId
                ? list?.userDetails?.accountId
                : "N/A"}
            </b>
          </td>
          <td className="green_status">
            ${list?.commission ? (list?.commission).toFixed(2) : "0"}
          </td>
          <td>${list?.orderPrice ? (list?.orderPrice).toFixed(2) : "0"}</td>
          <td>{list.stockName ? list?.stockName : "N/A"}</td>
          <td>${list?.stockPrice ? (list?.stockPrice).toFixed(2) : "0"}</td>
          <td
            onClick={() => renderTradesGraph(list?.symbol)}
            className="cursor-pointer"
            title="View"
          >
            <b>{list?.symbol}</b>
          </td>
          <td>{list?.scheduledType ? list?.scheduledType : "0"}</td>
          <td>
            <td>{getStatus(list.status)}</td>

            {/* <label className="badge badge-success payment_status">
              {list.status}
            </label> */}
          </td>
          <td>{list.qty ? list?.qty : "0"}</td>
          <td>
            {" "}
            <Link to={`${Path.schedule_order}/${list._id}`}>
              <label
                class="btn btn-sm"
                style={{ cursor: "pointer" }}
                title="View Schedule Order"
              >
                <i className="mdi mdi-eye"></i>
              </label>
            </Link>
          </td>
        </tr>
      ))
    );
  };
  return (
    <>
      <h4 className="card-title">
        <SearchFilter
          searchValue={searchValue}
          handleSearchChange={handleSearchChange}
          clearSerchFilter={clearSerchFilter}
          SearchText="Search by client/stock name"
          isSearchDate={false}
          isExportButton={true}
          isAddButton={false}
          isInput={true}
          filter={filter}
          setFilter={setFilter}
          isSelectOtherBox={false}
          dropdownField="status"
          csvData={csvDataP}
          setActivePage={setActivePage}
          isStartEndDateFilter={true}
          setfields={setfields}
          field={fields}
          key={key}
          setKey={setKey}
          csvDataDownload_={csvDataDownload_}
        />
      </h4>
      <div class="table-responsive">
        <table className="table common_th">
          <thead>
            <tr>
              <th onClick={() => handleSortingFunc("first_name")}>
                {" "}
                Client's Name{" "}
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "first_name"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "first_name"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th onClick={() => handleSortingFunc("accountId")}>
                {" "}
                Account Id{" "}
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "accountId"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "accountId"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th onClick={() => handleSortingFunc("commission")}>
                {" "}
                Commission{" "}
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "commission"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "commission"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th onClick={() => handleSortingFunc("orderPrice")}>
                {" "}
                Order Price{" "}
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "orderPrice"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "orderPrice"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th onClick={() => handleSortingFunc("name")}>
                {" "}
                Stock Name{" "}
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "name"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "name"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th onClick={() => handleSortingFunc("stockPrice")}>
                {" "}
                Stock Price
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "stockPrice"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "stockPrice"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th onClick={() => handleSortingFunc("symbol")}>
                {" "}
                Symbol
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "symbol"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "symbol"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th onClick={() => handleSortingFunc("scheduledType")}>
                {" "}
                Scheduled Type{" "}
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "scheduledType"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "scheduledType"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th> Status</th>
              <th onClick={() => handleSortingFunc("qty")}>
                {" "}
                Qty
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "qty"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "qty"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>{renderListS_()}</tbody>
        </table>
      </div>
      {listing?.length > 0 && (
        <div className="pagination-box-review">
          <ReactPagination
            activePage={filter?.page}
            itemsCountPerPage={PAGE_LIMIT_TRADE}
            totalItemsCount={itemsCountS}
            handlePageChange={handlePageChange}
          />
        </div>
      )}
      {listing?.length == 0 && (
        <div className="row">
          <div className="col-md-6 pt-5">
            <span>No data found...</span>
          </div>
        </div>
      )}
    </>
  );
};
const mapStateToPros = (state) => {
  return {
    itemsCountPerPage: state.Common.itemsCountPerPage,
    totalItemsCount: state.Common.totalItemsCount,
    loader: state.Common.loader,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ScheduleOrder);
