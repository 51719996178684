import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { PAGE_LIMIT } from "helpers/viewContent";
import ReactPagination from "components/shared/ReactPagination";
import { debounce } from "lodash";
import * as Path from "routes/paths";
import Helmet from "react-helmet";
import * as actions from "store/actions/index.js";

import { DATE_FORMAT_TIME_ } from "helpers/common";
import { TRADES, PAGE_LIMIT_TRADE } from "helpers/viewContent";

//services
import { getList_ } from "store/services/commonService";

// export default ({ dispatch, tabIndex, setIsLoading, handleShow }) => {
const ScheduleHistory = ({ dispatch, match }) => {
  const history = useHistory();

  let editkey = match.params.id;

  const [activePage, setActivePage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [orderIndex, setOrderIndex] = useState(0);
  const [key, setKey] = useState(0);

  const [filter, setFilter] = useState({
    page: activePage,
    limit: PAGE_LIMIT,
    order: 1,
    search: "",
    orderBy: "createdAt",
  });
  const [listing, setListing] = useState([]);

  //filter
  let listToDisplay = listing;
  if (filter?.search !== "") {
    const searchTerm = filter?.search.toLowerCase();
    listToDisplay = listing.filter((list) => {
      const itemName = list?.name?.toLowerCase();
      const itemClientName = list?.userDetails?.first_name.toLowerCase();
      const itemaccountId = list?.userDetails?.accountId.toLowerCase();
      return (
        itemName?.includes(searchTerm) ||
        itemClientName?.includes(searchTerm) ||
        itemaccountId?.includes(searchTerm)
      );
    });
  }
  const [fields, setfields] = useState({
    search_date: "",
  });
  let searchFilterDate = fields.search_date;

  const fetchData = async () => {
    try {
      let query = {
        ...filter,
        orderId: editkey,
        url: "getOrdersHistory",
      };
      setIsLoading(true);
      let res = await dispatch(getList_(query));
      setListing(res?.data?.data);
      setTotalItemsCount(res?.data?.totalcount);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const handleSortingFunc = (value) => {
    // Check if the clicked column is the same as the current orderBy column
    if (filter.orderBy === value) {
      // If yes, toggle the order (ascending to descending or vice versa)
      setFilter({
        ...filter,
        order: filter.order === -1 ? 1 : -1,
      });
    } else {
      // If no, set the orderBy to the clicked column and order to ascending
      setFilter({
        ...filter,
        order: -1,
        orderBy: value,
      });
    }
  };
  useEffect(() => {
    fetchData();
  }, [filter]);

  //pagination
  const handlePageChange = (page) => {
    setFilter({
      ...filter,
      page: page,
    });
    window.scrollTo(0, 0);
  };

  const handleSearchChange = (event) => {
    const inputValue = event.target.value;
    setFilter({
      ...filter,
      search: inputValue,
    });
  };

  const debouncedFetchData = useMemo(() => {
    return debounce(handleSearchChange, 300);
  }, []);

  const clearSerchFilter = () => {
    setFilter({
      ...filter,
      search: "",
      status: "",
    });
    setfields({
      search_date: "",
    });
    setKey((prevKey) => prevKey + 1);
  };
  const setOrderIndexFunc = () => {
    dispatch(actions.persist_store({ orderIndex: 3 }));

    // history.push(Path.trade);
  };
  const getStatus = (status, list) => {
    if (status === "accepted") {
      return <label className={"badge badge-success"}>{status}</label>;
    } else if (status === "pending") {
      return <label className={"badge badge-pending"}>{status}</label>;
    } else if (status === "canceled") {
      return <label className={"badge badge-danger"}>{status}</label>;
    }
  };
  const renderListS_ = () => {
    return (
      listing?.length > 0 &&
      listing.map((list, i) => (
        <tr key={i} style={{ cursor: "pointer" }} title="View">
          <td
            // onClick={() =>
            //   list?.userDetails?.accountId
            //     ? handleShow(list)
            //     : console.log("not exists")
            // }
            title="view"
            className="cursor-pointer"
          >
            <b>
              {list?.userDetails?.accountId
                ? list?.userDetails?.accountId
                : "N/A"}
            </b>
          </td>
          <td>
            {list?.userDetails?.first_name
              ? list?.userDetails?.first_name
              : "N/A"}
          </td>
          <td>
            {list?.orderData?.stockName ? list?.orderData?.stockName : "N/A"}
          </td>
          <td>
            $
            {list?.orderData?.orderPrice
              ? (list?.orderData?.orderPrice).toFixed(2)
              : "0"}
          </td>
          <td>{list?.orderData?.type ? list?.orderData?.type : "N/A"}</td>
          <td>
            {list?.orderData?.status === "canceled"
              ? list?.orderData?.time
                ? list?.orderData?.time
                : "N/A"
              : "N/A"}
          </td>
          <td>
            {" "}
            {list?.orderData?.createdAt
              ? DATE_FORMAT_TIME_(list?.orderData?.createdAt)
              : "N/A"}
          </td>
          <td>
            {" "}
            {list?.orderData?.status === "canceled"
              ? list?.orderData?.createdAt
                ? DATE_FORMAT_TIME_(list?.orderData?.createdAt)
                : "N/A"
              : "N/A"}
          </td>
          <td>{getStatus(list?.orderData?.status)}</td>
          <td title="view" className="cursor-pointer">
            <b>
              {list?.createdAt ? DATE_FORMAT_TIME_(list?.createdAt) : "N/A"}
            </b>
          </td>
        </tr>
      ))
    );
  };
  return (
    <>
      {/* <h4 className="card-title">
        <SearchFilter
          searchValue={searchValue}
          handleSearchChange={handleSearchChange}
          clearSerchFilter={clearSerchFilter}
          SearchText="Search by client/stock name"
          isSearchDate={false}
          isExportButton={true}
          isAddButton={false}
          isInput={true}
          filter={filter}
          setFilter={setFilter}
          isSelectOtherBox={false}
          dropdownField="status"
          csvData={csvDataP}
          setActivePage={setActivePage}
          isStartEndDateFilter={true}
          setfields={setfields}
          field={fields}
          key={key}
          setKey={setKey}
          csvDataDownload_={csvDataDownload_}
        />
      </h4>
      <div class="table-responsive">
        <table className="table common_th">
          <thead>
            <tr>
              <th onClick={() => handleSortingFunc("first_name")}>
                {" "}
                Client's Name{" "}
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "first_name"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "first_name"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th onClick={() => handleSortingFunc("accountId")}>
                {" "}
                Account Id{" "}
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "accountId"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "accountId"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th onClick={() => handleSortingFunc("commission")}>
                {" "}
                Commission{" "}
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "commission"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "commission"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th onClick={() => handleSortingFunc("orderPrice")}>
                {" "}
                Order Price{" "}
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "orderPrice"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "orderPrice"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th onClick={() => handleSortingFunc("name")}>
                {" "}
                Stock Name{" "}
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "name"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "name"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th onClick={() => handleSortingFunc("stockPrice")}>
                {" "}
                Stock Price
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "stockPrice"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "stockPrice"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th onClick={() => handleSortingFunc("symbol")}>
                {" "}
                Symbol
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "symbol"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "symbol"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th onClick={() => handleSortingFunc("scheduledType")}>
                {" "}
                Scheduled Type{" "}
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "scheduledType"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "scheduledType"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th> Status</th>
              <th onClick={() => handleSortingFunc("qty")}>
                {" "}
                Qty
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "qty"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "qty"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>{renderListS_()}</tbody>
        </table>
      </div> */}
      <Helmet title={TRADES.CURRENT_MODULE} />
      <div className="page-header">
        <h3 className="page-title">{TRADES.CURRENT_MODULE}</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={Path.trade} onClick={setOrderIndexFunc}>
                {TRADES.CURRENT_MODULE}
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Schedule orders
            </li>
          </ol>
        </nav>
      </div>

      <div class="table-responsive">
        <table className="table common_th">
          <thead>
            <tr>
              <th onClick={() => handleSortingFunc("accountId")}>
                {" "}
                Account ID{" "}
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "accountId"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "accountId"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th onClick={() => handleSortingFunc("name")}>
                {" "}
                Name{" "}
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "name"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "name"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th onClick={() => handleSortingFunc("stock")}>
                {" "}
                Stock{" "}
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "stock"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "stock"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th onClick={() => handleSortingFunc("amount")}>
                {" "}
                Amount{" "}
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "amount"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "amount"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th onClick={() => handleSortingFunc("type")}>
                {" "}
                Type{" "}
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "type"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "type"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th onClick={() => handleSortingFunc("time")}>
                {" "}
                Time{" "}
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "time"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "time"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th onClick={() => handleSortingFunc("datePlaced")}>
                {" "}
                Date Placed{" "}
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 &&
                    filter?.orderBy === "datePlaced                    "
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "datePlaced"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th onClick={() => handleSortingFunc("dateCancelled")}>
                {" "}
                Date Cancelled{" "}
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 &&
                    filter?.orderBy === "dateCancelled                    "
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "dateCancelled"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th>Status</th>
              <th onClick={() => handleSortingFunc("createdAt")}>
                {" "}
                Created At{" "}
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "createdAt"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "createdAt"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
            </tr>
          </thead>
          <tbody>{renderListS_()}</tbody>
        </table>
      </div>
      {listing?.length > 0 && (
        <div className="pagination-box-review">
          <ReactPagination
            activePage={filter?.page}
            itemsCountPerPage={PAGE_LIMIT_TRADE}
            totalItemsCount={totalItemsCount}
            handlePageChange={handlePageChange}
          />
        </div>
      )}
      {listing?.length == 0 && (
        <div className="row">
          <div className="col-md-6 pt-5">
            <span>No data found...</span>
          </div>
        </div>
      )}
    </>
  );
};
const mapStateToPros = (state) => {
  return {
    itemsCountPerPage: state.Common.itemsCountPerPage,
    totalItemsCount: state.Common.totalItemsCount,
    loader: state.Common.loader,
    orderIndex: state.persistStore.orderIndex,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ScheduleHistory);
