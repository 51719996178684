import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { IMG_LOGO_1, SIDEBAR_LOGIN_LOGO } from "utils/constant";
import { updateData_ } from "store/services/commonService";

export default ({
  show,
  handleClose,
  setShow,
  currentValue,
  setCurrentValue,
  setisUpdateApi,
  dispatch,
  filterType,
}) => {
  const [showSubmitBtn, setshowSubmitBtn] = useState(false);

  const CancelCloseTrade = async (value, type) => {
    try {
      setshowSubmitBtn(true);
      let obj = {
        url: type === "cancel" ? "cancelOrder" : "closePosition",
      };
      if (type === "cancel") {
        obj = {
          ...obj,
          orderId: value?.id,
          accountId: value?.userDetails?.accountId,
        };
      } else {
        obj = {
          ...obj,
          symbol: value?.symbol,
          accountId: value?.userDetails?.accountId,
        };
      }
      await dispatch(updateData_(obj));
      setshowSubmitBtn(false);
      setShow(false);
      setisUpdateApi(true);
    } catch (err) {
      console.log(err);
      setshowSubmitBtn(false);
    }
  };
  const CancelSheduleTrade = async (value, type) => {
    try {
      setshowSubmitBtn(true);
      let obj = {
        url: "cancelOrder",
        type: "scheduled",
        orderId: value?._id,
      };
      await dispatch(updateData_(obj));
      setshowSubmitBtn(false);
      setShow(false);
      setisUpdateApi(true);
    } catch (err) {
      console.log(err);
      setshowSubmitBtn(false);
    }
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="axis_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {filterType === "schedule" ? (
            <div className="axis_bank m-auto">
              <img
                src={
                  currentValue?.userDetails?.profileImage
                    ? currentValue?.userDetails?.profileImage
                    : SIDEBAR_LOGIN_LOGO
                }
                alt=""
                className="img-fluid"
              />
            </div>
          ) : (
            <div className="axis_bank m-auto">
              <img
                src={
                  currentValue?.imgUrl
                    ? currentValue?.imgUrl
                    : SIDEBAR_LOGIN_LOGO
                }
                alt=""
                className="img-fluid"
              />
            </div>
          )}
          {/* <h2 className="text-center my-2">Axis Bank ltd.</h2> */}
          {filterType === "schedule" ? (
            <div className="axix_modal_content my-3">
              <div className="line_up d-flex align-items-center gap-3 justify-content-center">
                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Stock Price</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      $
                      {currentValue?.stockPrice
                        ? currentValue.stockPrice.toFixed(2)
                        : "0"}
                    </label>
                  </li>
                </ul>

                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Qty</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      {currentValue?.qty ? (currentValue?.qty).toFixed(2) : "0"}
                    </label>
                  </li>
                </ul>
              </div>
              <div className="line_up d-flex align-items-center gap-3 justify-content-center">
                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Client Name</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">{`${currentValue?.userDetails?.first_name} ${currentValue?.userDetails?.last_name}`}</label>
                  </li>
                </ul>
              </div>

              <div className="line_up d-flex align-items-center gap-3 justify-content-center">
                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Stock Name</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      {currentValue?.stockName}
                    </label>
                  </li>
                </ul>
              </div>

              <div className="line_up d-flex align-items-center gap-3 justify-content-center">
                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Account Id</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      {currentValue?.userDetails?.accountId
                        ? currentValue?.userDetails?.accountId
                        : "N/A"}
                    </label>
                  </li>
                </ul>
              </div>

              <div className="line_up d-flex align-items-center gap-3 justify-content-center">
                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Symbol</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      {currentValue?.symbol ? currentValue?.symbol : "N/A"}
                    </label>
                  </li>
                </ul>

                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Order Price</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      $
                      {currentValue?.orderPrice
                        ? (currentValue?.orderPrice).toFixed(2)
                        : "0"}
                    </label>
                  </li>
                </ul>
                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Status</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      {currentValue?.status ? currentValue?.status : "N/A"}
                    </label>
                  </li>
                </ul>
              </div>

              <div className="line_up d-flex align-items-center gap-3 justify-content-center">
                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Scheduled Type</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      {currentValue?.scheduledType
                        ? currentValue?.scheduledType
                        : "N/A"}
                    </label>
                  </li>
                </ul>
                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Commission</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      {currentValue?.commission
                        ? (currentValue?.commission).toFixed(2)
                        : "0"}
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            <div className="axix_modal_content my-3">
              <div className="line_up d-flex align-items-center gap-3 justify-content-center">
                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Balance</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      $
                      {currentValue?.userDetails?.walletCredit
                        ? (currentValue.userDetails?.walletCredit).toFixed(2)
                        : "0"}
                    </label>
                  </li>
                </ul>

                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Qty</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      {currentValue?.qty}
                    </label>
                  </li>
                </ul>
              </div>
              <div className="line_up d-flex align-items-center gap-3 justify-content-center">
                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Client Name</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">{`${currentValue?.userDetails?.first_name} ${currentValue?.userDetails?.last_name}`}</label>
                  </li>
                </ul>
              </div>

              <div className="line_up d-flex align-items-center gap-3 justify-content-center">
                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Name</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      {currentValue?.name}
                    </label>
                  </li>
                </ul>
              </div>

              <div className="line_up d-flex align-items-center gap-3 justify-content-center">
                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Account Id</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      {currentValue?.userDetails?.accountId
                        ? currentValue?.userDetails?.accountId
                        : "N/A"}
                    </label>
                  </li>
                </ul>
              </div>

              <div className="line_up d-flex align-items-center gap-3 justify-content-center">
                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Symbol</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      {currentValue?.symbol ? currentValue?.symbol : "N/A"}
                    </label>
                  </li>
                </ul>

                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Amount</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      $
                      {currentValue?.price
                        ? (currentValue?.price).toFixed(2)
                        : "0"}
                    </label>
                  </li>
                </ul>
                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Status</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      {currentValue?.status ? currentValue?.status : "N/A"}
                    </label>
                  </li>
                </ul>
              </div>

              <div className="line_up d-flex align-items-center gap-3 justify-content-center">
                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Bought Price</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      $
                      {currentValue?.boughtPrice
                        ? (currentValue?.boughtPrice).toFixed(2)
                        : "0"}
                    </label>
                  </li>
                </ul>

                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Current Price</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      $
                      {currentValue?.current_price
                        ? (currentValue?.current_price).toFixed(2)
                        : "0"}
                    </label>
                  </li>
                </ul>
                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Equity</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      $
                      {currentValue?.userDetails?.equity
                        ? (currentValue?.userDetails?.equity).toFixed(2)
                        : "0"}
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </Modal.Body>
        {filterType !== "schedule" ? (
          <Modal.Footer>
            {/* {currentValue?.status === "opened" && (
              <button
                type="submit"
                onClick={() => CancelCloseTrade(currentValue, "close")}
                disabled={showSubmitBtn ? true : false}
                title="Close Trade"
                className="btn btn-gradient-primary me-2"
              >
                {showSubmitBtn ? "Wait.." : "Close Trade"}
              </button>
            )}
            {currentValue?.status === "pending" && (
              <button
                type="submit"
                onClick={() => CancelCloseTrade(currentValue, "cancel")}
                disabled={showSubmitBtn ? true : false}
                title="Cancel Trade"
                className="btn btn-gradient-primary me-2"
              >
                {showSubmitBtn ? "Wait.." : "Cancel Trade"}
              </button>
            )} */}
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            {currentValue?.status === "pending" && (
              <button
                type="submit"
                onClick={() => CancelSheduleTrade(currentValue, "close")}
                disabled={showSubmitBtn ? true : false}
                title="Cancel Trade"
                className="btn btn-gradient-primary me-2"
              >
                {showSubmitBtn ? "Wait.." : "Cancel Trade"}
              </button>
            )}
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};
