import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { ERROR_MESSAGE } from "helpers/constantsMessage";

export default ({ dispatch, files, setFiles, fields }) => {
  const handleFileChange = async (newFiles) => {
    const allowedTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/svg",
      "image/svg+xml",
    ];
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes

    console.log("newFiles?.type", newFiles?.type);
    if (!allowedTypes.includes(newFiles?.type)) {
      await ERROR_MESSAGE(
        "Invalid file type. Please choose a valid image (JPEG, PNG, JPG,SVG)."
      );
    } else if (newFiles?.size > maxSize) {
      await ERROR_MESSAGE(
        "File size exceeds the limit of 5MB. Please choose a smaller file."
      );
    } else {
      setFiles({
        file: newFiles,
        preview: await getBase64(newFiles),
      });
    }
  };
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  const renderImagePreviews = () => {
    if (files !== null) {
      // Check if files object is not null
      return (
        <>
          <div>
            <img
              src={files?.preview ? files.preview : files}
              alt={`Preview`}
              className="image-custom-css"
            />
          </div>
        </>
      );
    } else {
      // If files object is null
      return (
        <div>
          <p>File type not supported: {files.name}</p>
        </div>
      );
    }
  };
  return (
    <div className="App">
      <h3>Drag & Drop Files</h3>
      <FileUploader
        multiple={false}
        handleChange={handleFileChange}
        name="file"
      />
      {files && files !== "null" ? (
        renderImagePreviews()
      ) : (
        <p>No files uploaded yet</p>
      )}
    </div>
  );
};
