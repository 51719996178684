import React, { useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";

//imported
import * as Path from "routes/paths";
import { FAQ } from "helpers/viewContent";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//services
import { addFAQ_, updateFAQ_, viewFAQ_ } from "store/services/faqService";

const editorConfiguration = {
  toolbar: [
    "heading",
    "|",
    "bold",
    "italic",
    "link",
    "|",
    "bulletedList",
    "numberedList",
    "|",
    "blockQuote",
    "insertTable",
    "|",
    "mediaEmbed",
    "imageInsert",
    "fileBrowser",
    "exportPdf",
    "exportWord",
    "exportXml",
    "|",
    "undo",
    "redo",
  ],
  sourceEditing: {
    preview: true, // Enable the preview feature
    highlightActiveLine: true, // Enable highlighting the line the cursor is on
    highlightMatches: true, // Enable highlighting matching tag pairs
  },
};
const AddEdit = ({ dispatch, match, location }) => {
  const validationSchema = Yup.object().shape({
    indonesiaAnswer: Yup.string().required("Indonesia Answer is required"),
    indonesiaQuestion: Yup.string().required("Indonesia Question is required"),
    question: Yup.string().required("Question is required"),
    answer: Yup.string().required("Answer is required"),
    status: Yup.string().required("Status is required"),
    type: Yup.string().required("Type is required"),
  });
  let editKey = match?.params?.id;
  const urlParams = new URLSearchParams(location?.search);
  const position = urlParams.get("position") || "";
  const totalCount = urlParams.get("totalCount") || "";
  const validateData = async (data) => {
    try {
      await validationSchema.validate(data, { abortEarly: false });
      return { isValid: true, errors: {} };
    } catch (error) {
      const errors = {};
      error.inner.forEach((err) => {
        errors[err.path] = err.message;
      });
      return { isValid: false, errors };
    }
  };
  const history = useHistory();
  const [isShow, setIsShow] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [fieldValue, setFieldValue] = useState(null);
  const [fields, setfields] = useState({
    question: "",
    answer: "",
    status: 1,
    type: "",
    count: "",
    indonesiaQuestion: "",
    indonesiaAnswer: "",
  });

  const handleChange = useCallback(
    (name) => (evt) => {
      setfields((prevState) => ({
        ...prevState,
        [name]: evt.target.value,
      }));
      setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    },
    []
  );

  //submit setting data
  const handleSubmit = async (evt) => {
    try {
      evt.preventDefault();
      const validation = await validateData(fields);
      if (validation.isValid) {
        let obj = {
          ...fields,
        };
        setIsShow(true);
        if (editKey) {
          obj = {
            ...fields,
            FaqId: editKey,
          };
          await dispatch(updateFAQ_(obj));
        } else {
          delete obj.count;
          await dispatch(addFAQ_(obj));
        }
        setIsShow(false);
        history.push(Path.faq);
      } else {
        setValidationErrors(validation.errors);
      }
    } catch (err) {
      setIsShow(false);
      console.log(err);
    }
  };

  async function getDataView() {
    try {
      let res = await dispatch(viewFAQ_(editKey));
      setfields(res?.data);
      setFieldValue(res?.data?.answer);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    if (editKey) {
      getDataView();
    }
  }, []);

  return (
    <>
      <Helmet title={FAQ.CURRENT_MODULE} />
      <div className="page-header">
        <h3 className="page-title">{FAQ.CURRENT_MODULE}</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={Path.faq}>{FAQ.CURRENT_MODULE}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {editKey ? "Edit" : "Add"}
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="card graph_card">
            <div className="card-body">
              <h4 className="card-title">{editKey ? "Edit" : "Add"} FAQ</h4>
              <form className="forms-sample" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label for="exampleInputName1">Question*</label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="exampleInputName1"
                    placeholder="Question*"
                    name="question"
                    onChange={handleChange("question")}
                    value={fields.question}
                  />
                  {validationErrors.question && (
                    <div className="text-danger err">
                      {validationErrors.question}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label for="exampleInputName1">Indonesia Question*</label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="exampleInputName1"
                    placeholder="Indonesia Question*"
                    name="indonesiaQuestion"
                    onChange={handleChange("indonesiaQuestion")}
                    value={fields.indonesiaQuestion}
                  />
                  {validationErrors.indonesiaQuestion && (
                    <div className="text-danger err">
                      {validationErrors.indonesiaQuestion}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label for="exampleTextarea1">Answer*</label>
                  <CKEditor
                    editor={ClassicEditor}
                    config={editorConfiguration}
                    data={fields.answer}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setFieldValue(editor.getData());
                      setfields((prevState) => ({
                        ...prevState,
                        answer: editor.getData(),
                      }));
                      setValidationErrors({ answer: "" });
                    }}
                    className="ckeditor-control ckeditor-custom-height"
                  />
                  {validationErrors.answer && (
                    <div className="text-danger err">
                      {validationErrors.answer}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label for="exampleTextarea1">Indonesia Answer*</label>
                  <CKEditor
                    editor={ClassicEditor}
                    config={editorConfiguration}
                    data={fields.indonesiaAnswer}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      // console.log( { event, editor, data } );
                      setFieldValue(editor.getData());
                      setfields((prevState) => ({
                        ...prevState,
                        indonesiaAnswer: editor.getData(),
                      }));
                      setValidationErrors({ indonesiaAnswer: "" });
                    }}
                    className="ckeditor-control ckeditor-custom-height"
                  />
                  {validationErrors.indonesiaAnswer && (
                    <div className="text-danger err">
                      {validationErrors.indonesiaAnswer}
                    </div>
                  )}
                </div>
                <div className="form-group ">
                  <label htmlFor="exampleInputJobType">Type*</label>
                  <select
                    className="form-control"
                    id="exampleInputJobType"
                    name="type"
                    onChange={handleChange("type")}
                    value={fields.type}
                    style={{
                      padding: "1rem 0.75rem",
                      backgroundColor: "white",
                    }}
                  >
                    <option value="">Select Faq Type</option>
                    <option value="advisor">Working with an Advisor</option>
                    <option value="financial">
                      Financial Planning Support
                    </option>
                    <option value="investment">
                      Investment Management Support
                    </option>
                    <option value="legalInitial">Legal and Initial</option>
                    <option value="profileAccount">Profile and Account</option>
                    <option value="feesCharges">Fees and Charges</option>
                    <option value="understandingOrders">
                      Understanding Orders
                    </option>
                    <option value="stocksPortfolio">
                      Stocks and Portfolio
                    </option>
                    <option value="other">Other</option>
                  </select>
                  {validationErrors.type && (
                    <div className="text-danger err">
                      {validationErrors.type}
                    </div>
                  )}
                </div>
                {editKey && (
                  <div className="row">
                    <div className="form-group">
                      <label htmlFor="exampleInputJobType">Position*</label>
                      <input
                        type="number"
                        className="form-control"
                        id="exampleInputName1"
                        placeholder="Position*"
                        name="count"
                        min={1}
                        max={totalCount}
                        onChange={handleChange("count")}
                        value={fields.count}
                      />
                      {validationErrors.count && (
                        <div className="text-danger err">
                          {validationErrors.count}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="form-group">
                  <div className="row">
                    <label for="exampleInputPassword4">Status*</label>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="">
                          <input
                            type="radio"
                            className=""
                            value={1}
                            name="status"
                            onChange={handleChange("status")}
                            checked={fields.status == 1 ? true : false}
                          />{" "}
                          Active
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="">
                          <input
                            type="radio"
                            className=""
                            value={0}
                            name="status"
                            onChange={handleChange("status")}
                            checked={fields.status == 0 ? true : false}
                          />{" "}
                          Inactive{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                  {validationErrors.status && (
                    <div className="text-danger err">
                      {validationErrors.status}
                    </div>
                  )}
                </div>

                <button
                  type="submit"
                  disabled={isShow ? true : false}
                  className="btn btn-gradient-primary me-2"
                >
                  {isShow ? "Loading.." : "Sumit"}
                </button>
                <Link to={Path.faq}>
                  <button className="btn btn-outline-secondary btn-lg">
                    Back
                  </button>
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToPros = (state) => {
  return {};
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AddEdit);
