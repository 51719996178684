import React, { useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";

//imported
import * as Path from "routes/paths";
import * as actions from "store/actions/index.js";
import { CMS } from "helpers/viewContent";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//services
import { viewCms_, updateCms_, addCms_ } from "store/services/pagesService";

const editorConfiguration = {
  toolbar: [
    "heading",
    "|",
    "bold",
    "italic",
    "link",
    "|",
    "bulletedList",
    "numberedList",
    "|",
    "blockQuote",
    "insertTable",
    "|",
    "mediaEmbed",
    "imageInsert",
    "fileBrowser",
    "exportPdf",
    "exportWord",
    "exportXml",
    "|",
    "undo",
    "redo",
  ],
  sourceEditing: {
    preview: true, // Enable the preview feature
    highlightActiveLine: true, // Enable highlighting the line the cursor is on
    highlightMatches: true, // Enable highlighting matching tag pairs
  },
};
const AddEdit = ({ tabIndex, setTabIndex, dispatch, match }) => {
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    name: Yup.string().required("Name is required"),
    content: Yup.string().required("Description is required"),
  });
  let slugUrl = match?.params?.key;

  const validateData = async (data) => {
    try {
      await validationSchema.validate(data, { abortEarly: false });
      return { isValid: true, errors: {} };
    } catch (error) {
      const errors = {};
      error.inner.forEach((err) => {
        errors[err.path] = err.message;
      });
      return { isValid: false, errors };
    }
  };
  const history = useHistory();
  const [isShow, setIsShow] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [fieldValue, setFieldValue] = useState(null);
  const [fields, setfields] = useState({
    title: "",
    name: "",
    content: "",
  });

  const handleChange = useCallback(
    (name) => (evt) => {
      setfields((prevState) => ({
        ...prevState,
        [name]: evt.target.value,
      }));
      setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    },
    []
  );

  //submit setting data
  const handleSubmit = async (evt) => {
    try {
      evt.preventDefault();
      const validation = await validateData(fields);
      if (validation.isValid) {
        let obj = {
          ...fields,
        };
        setIsShow(true);
        if (slugUrl) {
          obj = {
            ...fields,
            ContentId: slugUrl,
          };
          await dispatch(updateCms_(obj));
        } else {
          await dispatch(addCms_(obj));
        }
        setIsShow(false);
        history.push(Path.cms);
        dispatch(actions.persist_store({ indexApp: 3 }));
      } else {
        setValidationErrors(validation.errors);
      }
    } catch (err) {
      setIsShow(false);
      console.log(err);
    }
  };

  async function getDataView() {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(viewCms_(slugUrl));
      setfields(res?.data);
      setFieldValue(res?.data?.content);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }
  useEffect(() => {
    if (slugUrl) {
      getDataView();
    }
  }, [tabIndex]);

  return (
    <>
      <Helmet title={CMS.CURRENT_MODULE} />
      <div className="page-header">
        <h3 className="page-title">{CMS.CURRENT_MODULE}</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={Path.cms}>{CMS.CURRENT_MODULE}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {slugUrl ? "Edit" : "Add"}
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="card graph_card">
            <div className="card-body">
              <h4 className="card-title">{slugUrl ? "Edit" : "Add"} Content</h4>
              <form className="forms-sample" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label for="exampleInputName1">Title*</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputName1"
                    placeholder="Title"
                    name="title"
                    onChange={handleChange("title")}
                    value={fields.title}
                  />
                  {validationErrors.title && (
                    <div className="text-danger err">
                      {validationErrors.title}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label for="exampleInputName1">Name*</label>
                  <div className="col-md-12">
                    <select
                      className="form-select"
                      name="key"
                      onChange={handleChange("name")}
                      value={fields.name}
                    >
                      <option value="">Select </option>
                      <option
                        value="TERMS_CONDITIONS"
                        selected={
                          fields.name === "TERMS_CONDITIONS" ? "selected" : ""
                        }
                      >
                        Terms & Conditions{" "}
                      </option>
                      <option
                        value="ABOUT_US"
                        selected={fields.name === "ABOUT_US" ? "selected" : ""}
                      >
                        About Us
                      </option>
                      <option
                        value="PRIVACY_POLICY"
                        selected={
                          fields.name === "PRIVACY_POLICY" ? "selected" : ""
                        }
                      >
                        Privacy Policy
                      </option>
                      <option
                        value="REFUND_POLICY"
                        selected={
                          fields.name === "REFUND_POLICY" ? "selected" : ""
                        }
                      >
                        Refund Policy
                      </option>
                      <option
                        value="TRADING_RULE"
                        selected={
                          fields.name === "TRADING_RULE" ? "selected" : ""
                        }
                      >
                        Trading Rule
                      </option>
                      <option
                        value="STATEMENT"
                        selected={fields.name === "STATEMENT" ? "selected" : ""}
                      >
                        Statement of Disclosure
                      </option>
                      <option
                        value="CUSTOMER_COMPLAINTS"
                        selected={
                          fields.name === "CUSTOMER_COMPLAINTS"
                            ? "selected"
                            : ""
                        }
                      >
                        Customer Complaints
                      </option>
                    </select>
                    {validationErrors.name && (
                      <div className="text-danger err">
                        {validationErrors.name}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label for="exampleTextarea1">Description*</label>
                  <CKEditor
                    editor={ClassicEditor}
                    config={editorConfiguration}
                    data={fields.content}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setfields((prevState) => ({
                        ...prevState,
                        content: editor.getData(),
                      }));
                      setValidationErrors((prevErrors) => ({
                        ...prevErrors,
                        content: "",
                      }));
                    }}
                    className="ckeditor-control ckeditor-custom-height"
                  />
                  {validationErrors.content && (
                    <div className="text-danger err">
                      {validationErrors.content}
                    </div>
                  )}
                </div>

                <button
                  type="submit"
                  disabled={isShow ? true : false}
                  className="btn btn-gradient-primary me-2"
                >
                  {isShow ? "Loading.." : "Submit"}
                </button>
                <Link to={Path.cms}>
                  <button className="btn btn-outline-secondary btn-lg">
                    Back
                  </button>
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToPros = (state) => {
  return {};
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AddEdit);
