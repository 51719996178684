import React, { useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { PAGE_LIMIT } from "helpers/viewContent";
import ReactPagination from "components/shared/ReactPagination";

import * as actions from "store/actions/index.js";
import { DATE_FORMAT_TIME_ } from "helpers/common";
//services
import { addData_, getList_ } from "store/services/commonService";

export default ({ dispatch, tabIndex, setIsLoading }) => {
  const [fields, setfields] = useState({
    price: "",
  });
  const [activePage, setActivePage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  const [filter, setFilter] = useState({
    page: activePage,
    limit: PAGE_LIMIT,
    order: 1,
    orderBy: "createdAt",
  });

  const [isShow, setIsShow] = useState(false);
  const [getData, setGetData] = useState([]);

  const handleChange = useCallback(
    (name) => (evt) => {
      setfields((prevState) => ({
        ...prevState,
        [name]: evt.target.value,
      }));
    },
    []
  );
  const handleSubmit = async (evt) => {
    try {
      evt.preventDefault();
      dispatch(actions.persist_store({ loader: true }));
      let query = {};
      query = {
        price: parseFloat(fields.price),
        url: "exchangeRate/add",
      };
      await dispatch(addData_(query));
      setIsShow(false);
      dispatch(actions.persist_store({ loader: false }));
      fetchData();
    } catch (err) {
      setIsShow(false);
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  };

  const fetchData = async () => {
    try {
      let query = {
        ...filter,
        url: "exchangeRate/getWithFilter",
      };
      setIsLoading(true);
      let res = await dispatch(getList_(query));
      setGetData(res?.data?.data);
      setTotalItemsCount(res?.data?.totalcount);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const handleSortingFunc = (value) => {
    // Check if the clicked column is the same as the current orderBy column
    if (filter.orderBy === value) {
      // If yes, toggle the order (ascending to descending or vice versa)
      setFilter({
        ...filter,
        order: filter.order === -1 ? 1 : -1,
      });
    } else {
      // If no, set the orderBy to the clicked column and order to ascending
      setFilter({
        ...filter,
        order: -1,
        orderBy: value,
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [filter, tabIndex]);

  //pagination
  const handlePageChange = (page) => {
    setFilter({
      ...filter,
      page: page,
    });
    window.scrollTo(0, 0);
  };

  //render html
  const renderList_ = () => {
    return (
      getData?.length > 0 &&
      getData.map((list, i) => (
        <tr key={i} style={{ cursor: "pointer" }}>
          <td>
            <b>{list?.name ? list?.name : "N/A"}</b>
          </td>
          <td>
            $
            {list.price != null && !isNaN(list.price)
              ? list.price.toFixed(2)
              : "0"}
          </td>
          <td>{DATE_FORMAT_TIME_(list.createdAt)}</td>
        </tr>
      ))
    );
  };

  return (
    <>
      <form className="forms-sample" onSubmit={handleSubmit}>
        <div className="row">
          <div className="form-group col-md-6">
            <label for="exampleInputName1">Exchange Rate*</label>
            <input
              type="number"
              className="form-control"
              id="exampleInputName1"
              placeholder="Exchange Rate"
              name="price"
              min={0}
              required
              step="0.01"
              onChange={handleChange("price")}
              value={fields.price || getData[0]?.price}
            />
          </div>
          <div className="form-group col-md-6">
            <button
              type="submit"
              disabled={isShow ? true : false}
              className="btn btn-gradient-primary me-2 mr-top "
            >
              {isShow ? "Wait.." : "Submit"}
            </button>
          </div>
        </div>
      </form>
      <div class="table-responsive">
        <table className="table common_th">
          <thead>
            <tr>
              <th onClick={() => handleSortingFunc("name")}>
                {" "}
                Name{" "}
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "name"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "name"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th onClick={() => handleSortingFunc("price")}>
                {" "}
                Price{" "}
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "price"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "price"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th onClick={() => handleSortingFunc("createdAt")}>
                {" "}
                Created At{" "}
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "createdAt"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "createdAt"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
            </tr>
          </thead>
          <tbody>{renderList_()}</tbody>
        </table>
      </div>
      {getData.length > 0 && (
        <div className="pagination-box-review">
          <ReactPagination
            activePage={filter?.page}
            itemsCountPerPage={PAGE_LIMIT}
            totalItemsCount={totalItemsCount}
            handlePageChange={handlePageChange}
          />
        </div>
      )}
      {getData.length == 0 && (
        <div className="row">
          <div className="col-md-6 pt-5">
            <span>No data found...</span>
          </div>
        </div>
      )}
    </>
  );
};
