import React from "react";
import Modal from "react-bootstrap/Modal";
import { IMG_LOGO_1, SIDEBAR_LOGIN_LOGO } from "utils/constant";

export default ({
  showInfoModal,
  handleInfoClose,
  currentInfoValue,
  setCurrentInfoValue,
}) => {
  return (
    <>
      <Modal
        show={showInfoModal}
        onHide={handleInfoClose}
        backdrop="static"
        keyboard={false}
        className="axis_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {/* <div className="axis_bank m-auto">
            <img
              src={
                currentInfoValue?.imgUrl
                  ? currentInfoValue?.imgUrl
                  : SIDEBAR_LOGIN_LOGO
              }
              alt=""
              className="img-fluid"
            />
          </div> */}
          {/* <h2 className="text-center my-2">Axis Bank ltd.</h2> */}
          <div className="axix_modal_content my-3">
            <div className="line_up d-flex align-items-center gap-3 justify-content-center">
              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>User Amount</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    $
                    {currentInfoValue?.userAmount
                      ? currentInfoValue.userAmount.toFixed(2)
                      : "0"}
                  </label>
                </li>
              </ul>
              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Commission</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    {currentInfoValue?.commission
                      ? (currentInfoValue?.commission).toFixed(2)
                      : "0"}
                  </label>
                </li>
              </ul>
            </div>
            <div className="line_up d-flex align-items-center gap-3 justify-content-center">
              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Transaction Id</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    {currentInfoValue?.transactionId
                      ? currentInfoValue?.transactionId
                      : "N/A"}
                  </label>
                </li>
              </ul>
            </div>
            <div className="line_up d-flex align-items-center gap-3 justify-content-center">
              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Transaction Type</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    {currentInfoValue?.transactionType
                      ? currentInfoValue?.transactionType
                      : "N/A"}
                  </label>
                </li>
              </ul>
              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Status</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    {currentInfoValue?.status
                      ? currentInfoValue?.status
                      : "N/A"}
                  </label>
                </li>
              </ul>
            </div>
            <div className="line_up d-flex align-items-center gap-3 justify-content-center">
              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Account Id</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    {currentInfoValue?.userDetails?.accountData?.account_number
                      ? currentInfoValue?.userDetails?.accountData
                          ?.account_number
                      : "N/A"}
                  </label>
                </li>
              </ul>
            </div>

            <div className="line_up d-flex align-items-center gap-3 justify-content-center">
              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>wallet Credit</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    $
                    {currentInfoValue?.userDetails?.walletCredit
                      ? (currentInfoValue?.userDetails?.walletCredit).toFixed(2)
                      : "0"}
                  </label>
                </li>
              </ul>
              {/* <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Currency</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    {currentInfoValue?.currency
                      ? currentInfoValue?.currency
                      : "USD"}
                  </label>
                </li>
              </ul> */}
              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Type</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    {currentInfoValue?.type
                      ? currentInfoValue?.type == "incoming"
                        ? "deposit"
                        : currentInfoValue?.type
                      : "N/A"}
                  </label>
                </li>
              </ul>
            </div>
            <div className="line_up d-flex align-items-center gap-3 justify-content-center">
              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Description</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    {currentInfoValue?.text ? currentInfoValue?.text : "N/A"}
                  </label>
                </li>
              </ul>
            </div>
            <hr />
            <h2 className="text-center my-2">Bank Details</h2>
            <hr />
            <div className="line_up d-flex align-items-center gap-3 justify-content-center">
              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Account Number</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    {currentInfoValue?.bankDetails?.accountNumber}
                  </label>
                </li>
              </ul>
            </div>
            <div className="line_up d-flex align-items-center gap-3 justify-content-center">
              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Currency</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    {currentInfoValue?.bankDetails?.currency
                      ? currentInfoValue?.bankDetails?.currency
                      : "---"}
                  </label>
                </li>
              </ul>
            </div>
            <div className="line_up d-flex align-items-center gap-3 justify-content-center">
              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Account Holder Name</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    {currentInfoValue?.bankDetails?.accountHolderName}
                  </label>
                </li>
              </ul>
            </div>
            {currentInfoValue?.comment && (
              <>
                <hr />
                <div className="line_up d-flex align-items-center gap-3 justify-content-center">
                  <ul className="d-flex align-items-center gap-3 justify-content-center">
                    <li>
                      <label className="col-form-label">
                        <strong>Comment</strong>
                      </label>
                    </li>
                    <li>
                      <label className="col-form-label">
                        {currentInfoValue?.comment
                          ? currentInfoValue?.comment
                          : "N/A"}
                      </label>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
