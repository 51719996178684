import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//imported
import ReactPagination from "components/shared/ReactPagination";
import { FAQ, PAGE_LIMIT } from "helpers/viewContent";
import { FORMAT_TEXT } from "helpers/common";
import { DELETE_ALERT } from "helpers/constantsMessage";
import * as Path from "routes/paths";
import * as actions from "store/actions/index.js";
//services
import { getFAQ_, deleteFAQ_ } from "store/services/faqService";

const Listing = ({
  dispatch,
  itemsCountPerPage,
  totalItemsCount,
  userProfile,
  user,
}) => {
  let userPermission =
    userProfile && userProfile?.permissions?.length > 0
      ? JSON.parse(userProfile?.permissions)
      : {};
  let userData =
    user?.permissions && user?.permissions?.length > 0
      ? JSON.parse(user?.permissions)
      : userPermission;
  let userRole = userProfile ? userProfile?.role : user?.role;

  const [userSubPermissions, setuserSubPermissions] = useState(null);
  //pagination
  const [activePage, setActivePage] = useState(1);
  const [filter, setFilter] = useState({
    page: activePage,
    limit: PAGE_LIMIT,
    orderBy: "count",
  });

  const [listing, setListing] = useState([]);
  async function getData() {
    try {
      let query = {
        ...filter,
      };
      let res = await dispatch(getFAQ_(query));
      setListing(res?.data.data);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    getData();
  }, [filter?.page]);

  //pagination
  const handlePageChange = (page) => {
    setFilter({
      ...filter,
      page: page,
    });
    window.scrollTo(0, 0);
  };

  //delete
  const deleteFAQData_ = async (id) => {
    try {
      await DELETE_ALERT().then(async (willDelete) => {
        if (willDelete) {
          try {
            let query = {
              FaqId: id,
              status: "deleted",
            };
            await dispatch(deleteFAQ_(query)).then(() => {
              getData();
            });
          } catch (err) {
            console.log(err);
          }
        }
      });
    } catch (err) {
      console.log(err);
      dispatch(actions.persist_store({ loader: false }));
    }
  };
  useEffect(() => {
    let objPer = userData["landingPage"];
    setuserSubPermissions(objPer);
  }, [userProfile]);

  const getType = (type) => {
    if (type === "advisor") {
      return "Working with an Advisor";
    } else if (type === "financial") {
      return "Financial Planning Support";
    } else if (type === "investment") {
      return "Investment Management Support";
    } else if (type === "legalInitial") {
      return "Legal and Initial";
    } else if (type === "profileAccount") {
      return "Profile and Account";
    } else if (type === "feesCharges") {
      return "Fees and Charges";
    } else if (type === "understandingOrders") {
      return "Understanding Orders";
    } else if (type === "stocksPortfolio") {
      return "Stocks and Portfolio";
    } else {
      return "Other";
    }
  };
  return (
    <>
      <Helmet title={FAQ.CURRENT_MODULE} />
      <div className="page-header">
        <h3 className="page-title">{FAQ.CURRENT_MODULE}</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={Path.faq}>{FAQ.CURRENT_MODULE}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Listing
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card graph_card">
            <div className="card-body">
              <h4 className="card-title">
                <div className="row">
                  <div className="col-md-6">Listing</div>
                  <div className="col-md-4"></div>
                  {userRole === "SUBADMIN" &&
                  userSubPermissions !== undefined &&
                  userSubPermissions?.create ? (
                    <div className="col-md-2">
                      <Link to={Path.faq_form} title="Add">
                        <button
                          type="button"
                          class="btn btn-lg btn-gradient-primary"
                        >
                          {" "}
                          + Add
                        </button>
                      </Link>
                    </div>
                  ) : (
                    userRole === "ADMIN" && (
                      // userSubPermissions === undefined && (
                      <div className="col-md-2">
                        <Link to={Path.faq_form} title="Add">
                          <button
                            type="button"
                            class="btn btn-lg btn-gradient-primary"
                          >
                            + Add
                          </button>
                        </Link>
                      </div>
                    )
                    // )
                  )}
                </div>
              </h4>
              <div class="table-responsive">
                <table className="table common_th">
                  <thead>
                    <tr>
                      <th> Question </th>
                      <th> Type </th>
                      <th> Answer </th>
                      <th> Status </th>
                      {userRole === "SUBADMIN" &&
                      userSubPermissions !== undefined &&
                      (userSubPermissions?.delete ||
                        userSubPermissions?.update) ? (
                        <th> Action</th>
                      ) : (
                        userRole === "ADMIN " && <th> Action</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {listing?.length > 0 &&
                      listing.map((list, i) => {
                        return (
                          <tr key={i}>
                            <td className="py-1">
                              {list?.question
                                ? FORMAT_TEXT(list?.question)
                                : "---"}
                            </td>
                            <td className="py-1">{getType(list?.type)}</td>
                            <td className="py-1">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: list?.answer
                                    ? FORMAT_TEXT(list?.answer)
                                    : "---",
                                }}
                              />
                            </td>
                            <td>
                              <label
                                style={{ cursor: "pointer" }}
                                className={`badge badge-${
                                  list?.status == 1
                                    ? "success payment_status"
                                    : "danger"
                                }`}
                              >
                                {list?.status == 1 ? "Active" : "Inactive"}
                              </label>
                            </td>
                            <td>
                              {userRole === "SUBADMIN" &&
                              userSubPermissions !== undefined ? (
                                <>
                                  {userSubPermissions?.update && (
                                    <>
                                      <Link
                                        to={`${Path.faq_form}/${list?._id}`}
                                      >
                                        <label
                                          class="btn btn-sm"
                                          style={{ cursor: "pointer" }}
                                          title="Edit"
                                        >
                                          <i className="mdi mdi-grease-pencil"></i>
                                        </label>
                                      </Link>
                                      &nbsp;&nbsp;
                                    </>
                                  )}
                                  {userSubPermissions?.delete && (
                                    <>
                                      <label
                                        className="btn btn-sm"
                                        style={{ cursor: "pointer" }}
                                        title="Delete"
                                        onClick={() =>
                                          deleteFAQData_(list?._id)
                                        }
                                      >
                                        <i className="mdi mdi-delete"></i>
                                      </label>
                                    </>
                                  )}
                                </>
                              ) : (
                                userRole === "ADMIN" && (
                                  // userSubPermissions === undefined && (
                                  <>
                                    <Link
                                      to={`${Path.faq_form}/${
                                        list?._id
                                      }?position=${
                                        i + 1
                                      }&totalCount=${totalItemsCount}`}
                                    >
                                      <label
                                        class="btn btn-sm"
                                        style={{ cursor: "pointer" }}
                                        title="Edit"
                                      >
                                        <i className="mdi mdi-grease-pencil"></i>
                                      </label>
                                    </Link>
                                    &nbsp;&nbsp;
                                    <label
                                      className="btn btn-sm"
                                      style={{ cursor: "pointer" }}
                                      title="Delete"
                                      onClick={() => deleteFAQData_(list?._id)}
                                    >
                                      <i className="mdi mdi-delete"></i>
                                    </label>{" "}
                                  </>
                                )
                                // )
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              {listing.length > 0 && (
                <div className="pagination-box-review">
                  <ReactPagination
                    activePage={filter?.page}
                    itemsCountPerPage={PAGE_LIMIT}
                    totalItemsCount={totalItemsCount}
                    handlePageChange={handlePageChange}
                  />
                </div>
              )}
              {listing.length == 0 && (
                <div className="row">
                  <div className="col-md-6 pt-5">
                    <span>No data found...</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    totalItemsCount: state.Faq.totalItemsCount,
    loader: state.Faq.loader,
    user: state.Auth.user,
    userProfile: state?.persistStore?.userProfile,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Listing);
