import { Button } from "@mui/material";
import React from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

export default ({ selectedImage, setSelectedImage }) => {
  return (
    <>
      <Modal
        show={selectedImage != null ? true : false}
        onHide={() => setSelectedImage(null)}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ maxWidth: "90%", margin: "auto" }} //
      >
        <Modal.Body
          style={{
            padding: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            height: "80vh", // Modal height adjustment
            position: "relative",
          }}
        >
          <Link
            to="#"
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              zIndex: 1050, // Ensure the close button is above other content
              textDecoration: "none",
              color: "black",
            }}
            onClick={() => setSelectedImage(null)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 24 24"
            >
              <path
                fill="#000"
                d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
              />
            </svg>
          </Link>
          <img
            src={selectedImage}
            alt="Selected"
            style={{
              width: "100%",
              height: "auto",
              display: "block",
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
