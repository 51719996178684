import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//imported
import * as Path from "routes/paths";
import * as actions from "store/actions/index.js";
import { TRADES } from "helpers/viewContent";
import { DATE_FORMAT_TIME } from "helpers/common";
//services
import { getDetail_ } from "store/services/commonService";

const Detail = ({ dispatch, match }) => {
  let editkey = match.params.id;
  const [editData, setEditData] = useState({});
  async function getData() {
    try {
      let query = {
        url: "post/view",
        id: editkey,
      };
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(getDetail_(query));
      setEditData(res?.body);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  useEffect(() => {
    if (editkey) {
      getData();
    }
  }, []);
  return (
    <>
      <Helmet title={TRADES.CURRENT_MODULE} />
      <div className="page-header">
        <h3 className="page-title">{TRADES.CURRENT_VIEW}</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={Path.trade}>Manage Trades</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Detail
            </li>
          </ol>
        </nav>
      </div>

      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card graph_card">
            <div className="card-body">
              <p className="card-description"> Post Info </p>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-6 col-form-label">Title</label>
                    <div className="col-sm-6 col-form-label">
                      {editData?.title ? editData?.title : "N/A"}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-6 col-form-label">Status</label>
                    <div className="col-sm-6 col-form-label">
                      <p
                        className={`badge badge-gradient-${
                          editData?.status == 1 ? "success" : "danger"
                        }`}
                      >
                        {editData?.status == 1 ? "Active" : "Inactive"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-6 col-form-label">
                      Description
                    </label>
                    <div className="col-sm-6 col-form-label">
                      {editData?.description ? editData?.description : "N/A"}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-6 col-form-label">
                      Created At
                    </label>
                    <div className="col-sm-6 col-form-label">
                      {editData?.created_at
                        ? DATE_FORMAT_TIME(editData?.created_at)
                        : "N/A"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-6 col-form-label">
                      Media Type
                    </label>
                    <div className="col-sm-6 col-form-label">
                      {editData?.media_type
                        ? editData?.media_type === "0"
                          ? "Picture"
                          : "Blog"
                        : "N/A"}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-6 col-form-label">Image</label>
                    <div className="col-sm-6 col-form-label">
                      <a
                        href={editData?.media ? `${editData?.media}` : "#"}
                        target="_blank"
                      >
                        {editData?.media && editData.media_type === "0" ? (
                          <img
                            src={`${editData?.media}`}
                            className="mb-2 rounded img-view"
                            alt="image"
                          />
                        ) : (
                          <video
                            src={`${editData?.media}`}
                            className="mb-2 rounded img-view-video"
                            alt="image"
                          />
                        )}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  return {};
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Detail);
