import React, { useState } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
//imported
import { dashboard, forgot_password } from "routes/paths";
import LoadingButton from "components/shared/LoadingButton";
import { LOGIN } from "helpers/viewContent";
import { MAIN_LOGO, SIDEBAR_LOGIN_LOGO } from "utils/constant";
//services
import { login } from "store/services/authService";

const renderField = ({
  input,
  placeholder,
  type,
  autoComplete,
  meta: { touched, error, warning },
}) => (
  <>
    <input
      {...input}
      type={type}
      placeholder={placeholder}
      autoComplete={autoComplete}
      className={`form-control ${touched && error ? `is-invalid` : ""}`}
    />

    {touched && error && <span className="invalid-feedback">{error}</span>}
  </>
);

const Login = (props) => {
  const history = useHistory();
  const [sending, setSending] = useState(false);
  const { handleSubmit, submitting } = props;

  const [values, setValues] = useState({
    username: "",
    password: "",
  });

  const handleSubmit_ = async (values) => {
    try {
      setSending(true);
      await props.dispatch(login(values)).then((res) => {
        setSending(false);

      
        if (res?.data?.status === "success") {
          history.push(dashboard);
        }
      });
    } catch (err) {
      console.log(err);
      setSending(false);
    }
  };

  return (
    <>
      <Helmet title={LOGIN.CURRENT_MODULE} />
      <div
        className=" authMainWrpper login-background-color d-flex align-items-center justify-content-center flex-column w-100"
        style={{ minHeight: "100vh" }}
      >
        <div className=" py-4 justify-content-center row align-items-center auth login-background-color w-100">
          <div className="col-lg-8">
            <div className="authLayout bg-white">
              <div className="row align-items-center">
                <div className="col-6 p-0 imgWrpper d-lg-block d-none">
                  <img
                    src={SIDEBAR_LOGIN_LOGO}
                    className=" img-fluid mainImg h-100 w-100"
                  />
                </div>
                <div className="col-lg-6">
                  <div className="auth-form-light bg-transparent text-left py-5 ps-lg-0  px-3">
                    <div className="brand-logo">
                      <img src={MAIN_LOGO} className="loginLogo" />
                    </div>
                    <h4>Login In to Admin Panel</h4>
                    <h6 className="font-weight-light">
                      Enter your phone number and password below
                    </h6>
                    <form
                      className="pt-3"
                      onSubmit={handleSubmit(handleSubmit_)}
                      autoComplete="off"
                    >
                      <div className="form-group">
                        <Field
                          name="username"
                          component={renderField}
                          placeholder="E-Mail/Phone Number*"
                          type="text"
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-group">
                        <Field
                          name="password"
                          component={renderField}
                          placeholder="Password*"
                          type="password"
                          autoComplete="new-password"
                        />
                      </div>
                      <div className="mt-3">
                        <LoadingButton
                          type="submit"
                          className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn col-md-12"
                          loading={sending}
                          disabled={submitting}
                        >
                          {!sending ? "SIGN IN" : "Loading..."}
                        </LoadingButton>
                      </div>
                      <div className="my-2 d-flex justify-content-between align-items-center">
                        <div className="form-check"></div>
                        <Link
                          to={forgot_password}
                          className="auth-link text-black"
                        >
                          Forgot password?
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const validate = (values) => {
  const errors = {};
  //email
  // if (!values.username) {
  //   errors.username = 'Email Required'
  // }
  // else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username)) {
  //   errors.username = 'Invalid email address'
  // }
  if (!values.username) {
    errors.username = "Email Required";
  }
  //password
  if (!values.password) {
    errors.password = "Password Required";
  } else if (values.password.length < 6) {
    errors.password = "Must be 6 characters or greater";
  }
  return errors;
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

const LoginForm = reduxForm({
  form: "login",
  validate,
})(Login);

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
